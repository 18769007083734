import React from 'react'
import styles from './App.module.css'

const Loading = () => {
  return (
    <div className={styles['lds-loading']} data-testid="lds-loading--test">
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

export default Loading
