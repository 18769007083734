import React, { useState, useEffect } from 'react'
import styles from './App.module.css'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Graph from './graph/Graph'
import ServiceTable from './logging/ServiceTable'
import ServiceList from './list/ServiceList'
import Navbar from './Navbar'
import ConfigsTable from './configs/ConfigsTable'
import { LoginPage } from './login/login'
import Logout from './components/Logout'
import PrivateRoute from './components/PrivateRoute'

const App = () => {
  const [response, setResponse] = useState(
    JSON.parse(localStorage.getItem('accessCode'))
  )
  let path
  useEffect(() => {
    path = location?.pathname
  }, [])

  return (
    <Router>
      {path !== '/login' && response !== null && <Navbar />}
      <main data-testid="pagemain--test" className={styles.pagemain}>
        <Switch>
          <Route exact path="/">
            <LoginPage onSetResponse={setResponse} response={response} />
          </Route>
          <PrivateRoute exact path="/servicelist" component={ServiceList} />
          <PrivateRoute exact path="/graph" component={Graph} />
          <PrivateRoute exact path="/loglevel" component={ServiceTable} />
          <PrivateRoute exact path="/configs" component={ConfigsTable} />
          <Route exact path="/login">
            <LoginPage onSetResponse={setResponse} response={response} />
          </Route>
          <Route exact path="/logout">
            <Logout onSetResponse={setResponse} />
          </Route>
        </Switch>
      </main>
    </Router>
  )
}

export default App
