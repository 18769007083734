import * as d3 from 'd3'
import React from 'react'
import styles from './D3NetworkGraph.module.css'
import { ZoomIn, ZoomOut } from '@material-ui/icons'

const width = 1700
const height = 1200
class D3NetworkGraph extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      simulation: this.simulation()
    }
  }

  componentDidMount() {
    this.drawGraph({ nodes: this.props.nodes, links: this.props.links })

    /*  
    Adds a listener that fixes the scroll position to the middle of the svg when window is resized
    so graph is always in view
    */

    const graphHTMLElement = document?.getElementsByClassName(
      styles.graph__scrollbox
    )?.[0]
    window.addEventListener('resize', () => this.setscrolling(graphHTMLElement))
  }

  componentDidUpdate() {
    d3.select('#service-graph').html(null)
    this.drawGraph({ nodes: this.props.nodes, links: this.props.links })
    this.state.simulation.alphaTarget(0.3).restart()
  }

  componentWillUnmount() {
    const graphHTMLElement = document?.getElementsByClassName(
      styles.graph__scrollbox
    )?.[0]
    this.state.simulation.stop()
    window.removeEventListener('resize', this.setscrolling(graphHTMLElement))
  }

  simulation() {
    return d3
      .forceSimulation()
      .force(
        'link',
        d3
          .forceLink()
          .id(function (d) {
            return d.id
          })
          .distance(function (d) {
            return 230
          })
      )
      .force('center', d3.forceCenter(width / 3, height / 3))
      .force('charge', d3.forceManyBody().strength(-1000))
      .force('x', d3.forceX())
      .force('y', d3.forceY())
  }

  setscrolling = element =>
    element?.scrollTo(window.innerHeight / 2, window.innerWidth / 2)

  dragStarted = (d, d3) => {
    if (!d3.event.active) this.state.simulation.alphaTarget(0.3).restart()
    d.fx = d3.event.x
    d.fy = d3.event.y
  }

  dragged = (d, d3) => {
    d.fx = d3.event.x
    d.fy = d3.event.y
  }

  dragEnded = (d, d3) => {
    if (!d3.event.active) this.state.simulation.alphaTarget(0)
    d.fx = null
    d.fy = null
  }

  addDrag = (d, d3) => {
    d.call(
      d3
        .drag()
        .on('start', d => this.dragStarted(d, d3))
        .on('drag', d => this.dragged(d, d3))
        .on('end', d => this.dragEnded(d, d3))
    )
  }

  drawGraph = graph => {
    const svg = d3
      .select('#service-graph')
      .append('g')
      .attr(
        'transform',
        d3.zoomIdentity.translate(width / 4, height / 4).scale(0.5)
      )
      .append('g')

    d3.select('#service-graph').call(
      d3
        .zoom()
        .scaleExtent([0.1, 2.0])
        .on('zoom', function () {
          svg.attr(
            'transform',
            d3.zoomIdentity
              .translate(d3.event.transform.x, d3.event.transform.y)
              .scale(d3.event.transform.k)
          )
          d3.select('#zoom__slider').property('value', d3.event.transform.k)
        })
    )

    d3.select('#zoomin-icon').on('click', function () {
      d3.select('#service-graph')
        .transition()
        .call(
          d3
            .zoom()
            .scaleExtent([0.1, 2.0])
            .on('zoom', function () {
              svg.attr('transform', d3.event.transform)
              d3.select('#zoom__slider').property('value', d3.event.transform.k)
            }).scaleBy,
          1.2
        )
    })

    d3.select('#zoomout-icon').on('click', function () {
      d3.select('#service-graph')
        .transition()
        .call(
          d3
            .zoom()
            .scaleExtent([0.1, 2.0])
            .on('zoom', function () {
              svg.attr('transform', d3.event.transform)
              d3.select('#zoom__slider').property('value', d3.event.transform.k)
            }).scaleBy,
          0.8
        )
    })

    d3.select('#zoom__slider').on('input', function () {
      d3.select('#service-graph')
        .transition()
        .call(
          d3.zoom().on('zoom', function () {
            svg.attr('transform', d3.event.transform)
          }).scaleTo,
          this.value
        )
    })
    svg.attr('preserveAspectRatio', 'xMinYMin meet')

    // Appends arrowhead markers on top of every link destination
    svg
      .append('defs')
      .append('marker')
      .attr('id', 'arrowhead')
      .attr('viewBox', '-0 -5 10 10')
      .attr('refX', 90)
      .attr('refY', 0)
      .attr('orient', 'auto')
      .attr('markerWidth', 7)
      .attr('markerHeight', 10)
      .attr('xoverflow', 'visible')
      .append('svg:path')
      .attr('d', 'M 0,-5 L 10 ,0 L 0,5')
      .attr('fill', '#aaa')
      .style('stroke', 'none')

    // Appends links between nodes.
    const link = svg
      .append('g')
      .attr('class', 'links')
      .attr('height', height)
      .attr('width', width)
      .selectAll('line')
      .data(graph.links)
      .enter()
      .append('line')
      .attr('marker-end', 'url(#arrowhead)')
      .attr(
        'class',
        d =>
          `${styles.links__line}
        ${
          d.status === 'UP'
            ? styles['links__line--up']
            : d.status === 'DOWN'
            ? styles['links__line--down']
            : d.status === 'DEGRADED'
            ? styles['links__line--degrade']
            : styles['links__line--noStatus']
        }`
      )

    // Appends all nodes on svg
    const node = svg
      .append('g')
      .attr('class', 'nodes')
      .attr('height', height)
      .attr('width', width)
      .selectAll('nodes')
      .data(graph.nodes)
      .enter()
      .append('g')
      .on('click', d => {
        this.props.handleRootNode(d.url)
        d3.select('#graph__tooltip').style('visibility', 'hidden')
      })
      .on('mouseover', d => {
        d3.select('#graph__tooltip').style('left', d3.event.pageX + 'px')
        d3.select('#graph__tooltip').style('visibility', 'visible')
        d3.select('#graph__tooltip').style('top', d3.event.pageY + 'px')
        d3.select('#tooltip--heading').text(d.name)
        d3.select('#tooltip--status').text(d.status)
        d.type === 'service'
          ? d3
              .select('#tooltip--url')
              .text(
                'Url : ' +
                  (this?.props?.nameUrlMap[d.name]
                    ? this?.props?.nameUrlMap[d.name]
                    : d?.url)
              )
          : d3.select('#tooltip--url').text('')
        d.type === 'datastore'
          ? d3.select('#tooltip--host').text('host : ' + d.host)
          : d3.select('#tooltip--host').text('')
        d.type === 'datastore'
          ? d3.select('#tooltip--database').text('database : ' + d.database)
          : d3.select('#tooltip--database').text('')
        d.type && d3.select('#tooltip--type').text('type : ' + d.type)
      })
      .on('mousemove', d => {
        d3.select('#graph__tooltip').style('top', d3.event.pageY - 10 + 'px')
        d3.select('#graph__tooltip').style('left', d3.event.pageX + 10 + 'px')
      })
      .on('mouseout', d => {
        d3.select('#graph__tooltip').style('visibility', 'hidden')
      })

      .attr('class', function (d) {
        return d.type === 'service' ? 'service' : 'datastore'
      })
      .attr('id', function (d) {
        return d.id
      })

    this.addDrag(node, d3)

    d3.selectAll('g.service')
      .attr('data-testid', 'serviceNode--test')
      .append('circle')
      .attr('r', 10)
      .attr('fill', function (d) {
        return d.status === 'UP'
          ? 'green'
          : d.status === 'DOWN'
          ? 'red'
          : d.status === 'DEGRADED'
          ? 'orange'
          : 'grey'
      })

    // Appends database icon if group is on the class datastore
    d3.selectAll('g.datastore')
      .attr('data-testid', 'databaseNode--test')
      .append('circle')
      .attr('r', 6)
      .attr('fill', '#999')

    const label = svg
      .append('g')
      .attr('class', styles.labels)
      .selectAll('text')
      .data(graph.nodes)
      .enter()
      .append('text')
      .attr('class', 'label')
      .text(function (d) {
        return d.name
      })

    this.state.simulation
      .nodes(graph.nodes)
      .on('tick', () => this.ticked(this, link, node, label))
    this.state.simulation.force('link').links(graph.links)
  }

  ticked(d, link, node, label) {
    link
      .attr('x1', function (d) {
        return d.source.x
      })
      .attr('y1', function (d) {
        return d.source.y
      })
      .attr('x2', function (d) {
        return d.target.x
      })
      .attr('y2', function (d) {
        return d.target.y
      })

    node.attr('transform', function (d) {
      return 'translate(' + d.x + ',' + d.y + ')'
    })

    label
      .attr('x', function (d) {
        return d.x + 13
      })
      .attr('y', function (d) {
        return d.y + 9
      })
  }

  render() {
    return (
      <div className={styles.graph__scrollbox}>
        <div className={styles.graph__inner}>
          <svg
            data-testid="graph--test"
            className={styles.graph__svg}
            id="service-graph"
          ></svg>
          <label className={styles.graph__zoom}>
            <div id="zoomout-icon" className={styles.zoomout__icon}>
              <ZoomOut data-testid="zoomout--test" />
            </div>
            <div id="slider"></div>

            <input
              type="range"
              defaultValue="1.0"
              className={styles.zoom__slider}
              id="zoom__slider"
              min="0.1"
              max="2.0"
              step="0.1"
              data-testid="zoomSlider--test"
            />

            <div id="zoomin-icon">
              <ZoomIn
                data-testid="zoomin--test"
                className={styles.zoomin__icon}
              />
            </div>
          </label>
          <div className={styles.graph__tooltip} id="graph__tooltip">
            <div
              className={styles.tooltip__heading}
              id="tooltip--heading"
            ></div>

            <div>
              <span className={styles.tooltip__entryname}>Status : </span>
              <span
                className={styles.tooltip__status}
                id="tooltip--status"
              ></span>
            </div>

            <div>
              <span id="tooltip--url"></span>
            </div>

            <div>
              <span id="tooltip--host"></span>
            </div>
            <div>
              <span id="tooltip--database"></span>
            </div>
            <div>
              <span id="tooltip--type"></span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default D3NetworkGraph
