import React, { useState, useEffect } from 'react'
import D3NetworkGraph from './D3NetworkGraph'
import Loading from '../Loading'
import GraphTopbar from './GraphTopbar'
import { makeStyles } from '@material-ui/core'
import axios from 'axios'
import Error from '../Error'
import { useHistory } from 'react-router'
import { HEALTH_CHECK_URL, LEVEL_UPDATE } from '../constants'

const useStyles = makeStyles(theme => ({
  graph__outer: { width: '100%', border: '10px' }
}))

const Graph = () => {
  const [graphNodes, setGraphNodes] = useState([])
  const [graphLinks, setGraphLinks] = useState([])
  const [loading, isLoading] = useState(true)
  const [graphNameUrlMap, setGraphNameUrlMap] = useState({})
  const [filterNodes, setFilterNodes] = useState([])
  const [filterLinks, setFilterLinks] = useState([])
  const [perspective, setPerspective] = useState('upstream')
  const [response, setResponse] = useState(
    JSON.parse(localStorage.getItem('accessCode'))
  )
  const [error, isError] = useState(false)
  const [rootNode, setRootNode] = useState(HEALTH_CHECK_URL)
  const history = useHistory()

  const refreshApi = async isGetAll => {
    try {
      const newAccessToken = await axios.post(
        `${LEVEL_UPDATE}/refresh`,
        { refreshToken: response?.refreshToken },
        { headers: { Authorization: `Bearer ${response?.accessToken}` } }
      )
      setResponse(prev => ({
        ...prev,
        accessToken: newAccessToken?.data?.data?.accessToken
      }))
      if (isGetAll) {
        const tokens = JSON.parse(localStorage.getItem('accessCode')) || {}
        tokens.accessToken = newAccessToken?.data?.data?.accessToken
        localStorage.setItem('accessCode', JSON.stringify(tokens))
        await axios.get(HEALTH_CHECK_URL, {
          headers: { Authorization: `Bearer ${tokens?.accessToken}` }
        })
      }
    } catch (err) {
      history.push('/logout')
    }
  }

  useEffect(async () => {
    try {
      await refreshApi(false)
      const healthCheckResponse = await axios.get(HEALTH_CHECK_URL, {
        headers: { Authorization: `Bearer ${response?.accessToken}` }
      })
      const services = healthCheckResponse.data.data
      const graph = getGraphFromServices(services)
      setGraphNodes(graph.nodes)
      setGraphLinks(graph.links)
      setGraphNameUrlMap(graph?.nameUrlMap)
      setFilterNodes(graph.nodes)
      setFilterLinks(graph.links)
      isLoading(false)
      isError(false)
    } catch (err) {
      if (err?.response?.status === 401) {
        await refreshApi(true)
      }
      isError(true)
      isLoading(false)
    }
  }, [])

  const classes = useStyles()

  return loading ? (
    <Loading />
  ) : error ? (
    <Error />
  ) : (
    <div className={classes.graph__outer}>
      <GraphTopbar
        handleGraphNodesChanged={setGraphNodes}
        handleGraphLinksChanged={setGraphLinks}
        filterNodes={filterNodes}
        filterLinks={filterLinks}
        rootNode={rootNode}
        handleRootNode={setRootNode}
        perspective={perspective}
        handlePerspective={setPerspective}
      />
      <D3NetworkGraph
        nodes={graphNodes}
        links={graphLinks}
        nameUrlMap={graphNameUrlMap}
        handleRootNode={setRootNode}
      />
    </div>
  )
}

const getNameFromURL = name => {
  let splitArray = name.replace('https://', '')
  splitArray = splitArray.replace('http://', '')

  return splitArray
}

// removes special characters and numbers from name
const getCompactName = name =>
  name
    .replace(/[^a-zA-Z0-9]/g, '')
    .replace(' ', '')
    .toLowerCase()

const getGraphFromServices = services => {
  const nodes = []
  const nodeIDs = new Set()
  const URLMap = {}
  const links = []
  const serviceCompactNames = []
  const serviceids = {}
  const nameUrlMap = {}
  const serviceStatus = {}
  const nodeToBeRemoved = []
  const getdbs = (dbService, id, existingIds, multipleDbName) => {
    dbService?.lastCheck?.response?.details?.databases?.forEach(dataStore => {
      const {
        name: dbName,
        host: dbHost,
        status: dbStatus,
        database
      } = dataStore
      const dbId = dbHost
        ? dbHost.toLowerCase()
        : name + ' datastore: ' + dbName

      if (!nodeIDs.has(dbId)) {
        nodes.push({
          name: dbName,
          id: dbId,
          type: 'datastore',
          status: dbStatus,
          host: dbHost,
          database: database
        })
        nodeIDs.add(dbId)
      }
      // Add Relations
      links.push({
        source: multipleDbName ? existingIds[multipleDbName] : id,
        target: dbId,
        status: dbStatus
      })
    })
  }

  services?.forEach(service => {
    let { name, url, status } = service
    const compactName = getCompactName(name)

    if (compactName === 'healthcheck') url = HEALTH_CHECK_URL

    // Uncomment if statements if any such node in present in data which is without any property from the set(url, internalURL, name)

    const id = url || compactName

    nameUrlMap[name] = url
    URLMap[url] = compactName
    URLMap[compactName] = url
    if (!serviceCompactNames.includes(compactName)) {
      nodes.push({
        name,
        id,
        url,
        type: 'service',
        status
      })
      // Have to uncomment if statements if any such node in present in data which is without any property from the set(url, internalURL, name)
      nodeIDs.add(url)
      nodeIDs.add(compactName)
      if (id !== HEALTH_CHECK_URL) {
        links.push({
          source: HEALTH_CHECK_URL,
          target: id,
          status
        })
      }
      serviceStatus[url] = status
      // Add Datastore
      getdbs(service, id)
      // Add all services node from details
      service?.lastCheck?.response?.details?.services?.forEach(
        externalService => {
          let {
            name: externalServiceName,
            url: externalServiceURL,
            status: externalServiceStatus
          } = externalService

          if (externalServiceName?.includes('http')) {
            externalServiceURL = externalServiceName
            externalServiceName = getNameFromURL(externalServiceName)
          }
          const externalServiceCompactName = getCompactName(externalServiceName)
          let externalId = externalServiceURL
          if (URLMap[externalServiceCompactName]) {
            externalId = URLMap[externalServiceCompactName]
          }
          nodeToBeRemoved.push(
            nodes[nodes.findIndex(node => node.url === externalId)]?.url
          )
          if (
            !(
              nodeIDs.has(externalServiceURL) ||
              nodeIDs.has(externalServiceCompactName)
            )
          ) {
            nodes.push({
              name: externalServiceName,
              id: externalId,
              type: 'service',
              status: externalServiceStatus,
              url: externalId
            })
            nodeIDs.add(externalServiceURL)
            nodeIDs.add(externalServiceCompactName)
          }
          // Add relations
          links.push({
            source: id,
            target: externalId,
            status: externalServiceStatus
          })
          serviceids[externalServiceName] = externalId
          serviceCompactNames.push(externalServiceCompactName)
        }
      )
    } else if (Object.keys(serviceids).includes(name)) {
      getdbs(service, id, serviceids, name)
      service?.lastCheck?.response?.details?.services?.forEach(
        externalService => {
          let {
            name: externalServiceName,
            url: externalServiceURL,
            status: externalServiceStatus
          } = externalService

          externalServiceURL = externalServiceName
          externalServiceName = getNameFromURL(externalServiceName)

          const externalServiceCompactName = getCompactName(externalServiceName)
          let externalId = externalServiceURL
          if (URLMap[externalServiceCompactName]) {
            externalId = URLMap[externalServiceCompactName]
          }
          if (
            !(
              nodeIDs.has(externalServiceURL) ||
              nodeIDs.has(externalServiceCompactName)
            )
          ) {
            nodes.push({
              name: externalServiceName,
              id: externalId,
              type: 'service',
              status: serviceStatus[externalId] || externalServiceStatus,
              url: externalId
            })
            nodeIDs.add(externalServiceURL)
            nodeIDs.add(externalServiceCompactName)
          }
          // Add relations
          links.push({
            source: serviceids[name],
            target: externalId,
            status: externalServiceStatus
          })
          serviceids[externalServiceName] = externalId
          serviceCompactNames.push(externalServiceCompactName)
        }
      )
      nodes[nodes.findIndex(node => node.name === name)].status = status
    }
  })
  nodeToBeRemoved.forEach(node => {
    if (node) {
      links.splice(
        links.findIndex(
          link => link.target === node && link.source === HEALTH_CHECK_URL
        ),
        1
      )
    }
  })
  return { links, nodes, nameUrlMap }
}

export default Graph
