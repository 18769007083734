import React, { useEffect } from 'react'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core'
import { HEALTH_CHECK_URL } from '../constants'

const useStyles = makeStyles(() => ({
  graph__topbar: {
    padding: '1rem'
  },
  graph__filterservice: {
    margin: '0rem 0.5rem'
  },
  select: {
    minWidth: '150px'
  },
  selectIcon: {
    position: 'absolute',
    color: 'white',
    fontSize: '20px'
  },
  paper: {
    borderRadius: 8,
    marginTop: 20
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    '& li': {
      fontWeight: 200,
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: '16px'
    },
    '& li.Mui-selected': {
      color: 'white',
      background: '#FF4285'
    },
    '& li.Mui-selected:hover': {
      background: '#FF4285'
    }
  }
}))

const GraphTopbar = ({
  handleGraphNodesChanged,
  handleGraphLinksChanged,
  filterNodes,
  filterLinks,
  rootNode,
  handleRootNode,
  perspective,
  handlePerspective
}) => {
  const classes = useStyles()
  const menuProps = {
    classes: {
      list: classes.list,
      paper: classes.paper
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'center'
    },
    getContentAnchorEl: null
  }

  useEffect(() => {
    if (perspective === 'upstream') filterGraphByServiceUpstream()
    else if (perspective === 'downstream') filterGraphByServiceDownstream()
    else filterGraphByServiceNeighbours()
  }, [rootNode, perspective])

  const handleFilterServiceChange = e => {
    if (rootNode === HEALTH_CHECK_URL) handlePerspective('neighbours')
    handleRootNode(e.target.value)
    if (e.target.value === HEALTH_CHECK_URL) handlePerspective('upstream')
  }

  const filterGraphByServiceUpstream = () => {
    const newLinks = []
    const visitedNodes = new Set()
    const stack = []
    stack.push(rootNode)
    visitedNodes.add(rootNode)
    while (stack.length !== 0) {
      const currNode = stack.pop()
      visitedNodes.add(currNode)
      filterLinks.forEach(link => {
        if (link.source.id === currNode) {
          newLinks.push(link)
          if (!visitedNodes.has(link.target.id)) {
            visitedNodes.add(link.target.id)
            stack.push(link.target.id)
          }
        }
      })
    }
    handleGraphLinksChanged(newLinks)
    handleGraphNodesChanged(
      filterNodes.filter(node => visitedNodes.has(node.id))
    )
  }

  const filterGraphByServiceDownstream = () => {
    const newLinks = []
    const visitedNodes = new Set()
    const stack = []
    stack.push(rootNode)
    visitedNodes.add(rootNode)
    while (stack.length !== 0) {
      const currNode = stack.pop()
      visitedNodes.add(currNode)
      filterLinks.forEach(link => {
        if (link.target.id === currNode) {
          newLinks.push(link)
          if (!visitedNodes.has(link.source.id)) {
            visitedNodes.add(link.source.id)
            stack.push(link.source.id)
          }
        }
      })
    }
    handleGraphLinksChanged(newLinks)
    handleGraphNodesChanged(
      filterNodes.filter(node => visitedNodes.has(node.id))
    )
  }

  const filterGraphByServiceNeighbours = () => {
    const newLinks = []
    const visitedNodes = new Set()
    filterLinks.forEach(link => {
      if (link.source.id === rootNode || link.target.id === rootNode) {
        newLinks.push(link)
        visitedNodes.add(link.source.id)
        visitedNodes.add(link.target.id)
      }
    })
    handleGraphLinksChanged(newLinks)
    handleGraphNodesChanged(
      filterNodes.filter(node => visitedNodes.has(node.id))
    )
  }

  return (
    <div className={classes.graph__topbar}>
      <FormControl
        variant="outlined"
        color="secondary"
        className={classes.graph__filterservice}
        data-testid="graph__filterservice--test"
        htmlFor="filter-by-service"
      >
        <InputLabel
          id="filter-by-service-outline-label"
          data-testid="graph__filterlabel--test"
        >
          Filter By
        </InputLabel>
        <Select
          value={rootNode}
          onChange={handleFilterServiceChange}
          labelId="filter-by-service-outline-label"
          id="filter-by-service-outline"
          name="filter-by-service-outline"
          label="Filter By"
          MenuProps={menuProps}
          classes={{
            select: classes.select,
            icon: classes.selectIcon
          }}
          data-testid="graph__serviceinput--test"
        >
          <MenuItem aria-label="All" value={HEALTH_CHECK_URL}>
            All
          </MenuItem>
          {filterNodes
            .filter(
              node => node.type === 'service' && node.id !== HEALTH_CHECK_URL
            )
            .sort((nodeA, nodeB) => nodeA.name.localeCompare(nodeB.name))
            .map(node => {
              return (
                <MenuItem key={`filter-option-${node.id}`} value={node.id}>
                  {node.name}
                </MenuItem>
              )
            })}
        </Select>
      </FormControl>
      {rootNode !== HEALTH_CHECK_URL && (
        <FormControl
          variant="outlined"
          color="secondary"
          className={classes.graph__filterservice}
          data-testid="graph__filterperspective--test"
        >
          <InputLabel id="filter-by-perspective-outline-label">
            Perspective
          </InputLabel>
          <Select
            value={perspective}
            onChange={e => handlePerspective(e.target.value)}
            label="Perspective"
            labelId="filter-by-perspective-outline-label"
            id="filter-by-perspective-outline"
            name="filter-by-perspective-outline"
            data-testid="graph__filterperspectiveselect--test"
            MenuProps={menuProps}
            classes={{
              select: classes.select,
              icon: classes.selectIcon
            }}
          >
            <MenuItem value="neighbours">Immediate neighbours</MenuItem>
            <MenuItem value="upstream">Upstream</MenuItem>
            <MenuItem value="downstream">Downstream</MenuItem>
          </Select>
        </FormControl>
      )}
    </div>
  )
}

export default GraphTopbar
