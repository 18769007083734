import TableModal from './TableModel'
import React from 'react'
import { useToolbarStyles } from './TableStyles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { FilterList } from '@material-ui/icons'
import { Button } from '@material-ui/core'
const EnhancedTableToolbar = ({
  handleServiceAdded,
  searchEnabled,
  tableData,
  handleFilter,
  filterValue,
  handleClearFilterResults,
  page,
  response
}) => {
  const classes = useToolbarStyles()
  return (
    <Toolbar className={classes.root} data-testid={'tableToolBar--test'}>
      <Typography
        className={classes.title}
        variant="h5"
        id="tableTitle"
        component="div"
      >
        Services
      </Typography>
      <Button
        color="secondary"
        variant="contained"
        className={classes.filterList}
        onClick={() => handleFilter(!filterValue)}
        data-testid={'filterButton--test'}
        disabled={tableData?.length === 0}
      >
        Filter
        <FilterList />
      </Button>
      <TableModal
        response={response}
        tableData={tableData}
        handleServiceAdded={handleServiceAdded}
        page={page}
      />
    </Toolbar>
  )
}
export default EnhancedTableToolbar
