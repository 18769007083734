import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import ServiceListChip from './ServiceListChip'
import ReactTooltip from 'react-tooltip'

const useStyles = makeStyles(theme => ({
  tablecell__datalabels: {
    maxWidth: '300px'
  },
  tablecell_name: {
    cursor: 'pointer'
  },
  tooltip__text: {
    cursor: 'pointer'
  }
}))

const getModifiedDate = date => {
  const datetime = new Date(date).getTime()
  const now = new Date().getTime()
  let milisecDiff = 0
  milisecDiff = now - datetime
  const dateDiff = new Date(milisecDiff)
  return dateDiff.getSeconds() + 's ' + 'ago'
}

const getDateFromTimeZone = date => {
  return String(
    new Date(date).toLocaleString('en-US', {
      hour12: true,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      dateStyle: 'long',
      timeStyle: 'short'
    })
  )
}
const getNameFromURL = name => {
  const splitArray = name
    .replace('.com', '')
    .replace('https://')
    .replace('http://', '')
    .split('.')
  const res = splitArray[0].split('/')
  return res[0].replace('undefined', '')
}
const handleOpenUrl = url => {
  window.open(url, '_blank')
}
const ServiceListRow = props => {
  const classes = useStyles()
  const {
    // owner,
    name,
    url,
    status,
    lastchecked,
    lastlatency,
    framework,
    version,
    databases,
    services
  } = props.rowData
  const key = props.id
  return (
    <TableRow hover tabIndex={-1} key={key}>
      {/* <TableCell
        key={`owner-${key}`}
        component="th"
        scope="row"
        data-testid="servicelist__row--test"
        align="center"
      >
        {owner}
      </TableCell> */}
      <TableCell
        key={`name-${key}`}
        component="th"
        scope="row"
        padding="none"
        align="center"
        onClick={() => handleOpenUrl(url)}
      >
        <div className={classes.tablecell_name}>{name}</div>
      </TableCell>
      <TableCell
        key={`framework-${key}`}
        component="th"
        scope="row"
        align="center"
      >
        {framework}
      </TableCell>
      <TableCell
        key={`version-${key}`}
        component="th"
        scope="row"
        align="center"
      >
        {version}
      </TableCell>
      <TableCell
        key={`status-${key}`}
        component="th"
        scope="row"
        align="center"
      >
        {status}
      </TableCell>
      <TableCell
        key={`lastchecked-${key}`}
        component="th"
        scope="row"
        align="center"
      >
        <ReactTooltip type="light" data-testid="tooltip--test" />
        <div
          data-tip={getDateFromTimeZone(lastchecked)}
          className={classes.tooltip__text}
          data-testid="tooltip-text--test"
        >
          {getModifiedDate(lastchecked)}
        </div>
      </TableCell>
      <TableCell
        key={`lastlatency-${key}`}
        component="th"
        scope="row"
        align="center"
      >
        {lastlatency}
      </TableCell>
      <TableCell
        key={`databases-${key}`}
        component="th"
        scope="row"
        className={classes.tablecell__datalabels}
      >
        {databases?.map(({ name, status, host, database }, index) => {
          return (
            <ServiceListChip
              key={`database-${index}-chip-${key}`}
              name={name}
              status={status}
              host={host}
              database={database}
            />
          )
        })}
      </TableCell>
      <TableCell
        key={`services-${key}`}
        component="th"
        scope="row"
        padding="none"
      >
        {services?.map(({ name, status, url }, index) => {
          if (name?.includes('http')) {
            url = name
            name = getNameFromURL(name)
          }
          return (
            <ServiceListChip
              key={`service-${index}-chip-${key}`}
              name={name}
              status={status}
              url={url}
            />
          )
        })}
      </TableCell>
    </TableRow>
  )
}

export default ServiceListRow
