import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CloudOffIcon from '@material-ui/icons/CloudOff'
import Button from '@material-ui/core/Button'

const useErrorStyles = makeStyles(theme => ({
  errorIcon: {
    height: 100,
    width: 100
  },
  errorBlock: {
    display: 'flex',
    margin: 'auto',
    marginTop: '4rem',
    maxWidth: '25rem',
    backgroundColor: '#262b48',
    flexDirection: 'column',
    justifyContent: 'center',
    fontSize: '1.2rem'
  },
  errorBlockTop: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#ff6b87',
    fontSize: '1.6rem',
    fontWeight: 700,
    alignItems: 'center',
    padding: '3rem 0rem'
  },
  errorBody: {
    fontWeight: '100',
    padding: '2rem',
    paddingTop: '4rem',
    textAlign: 'center'
  },
  errorRetry: {
    padding: '2rem',
    textAlign: 'center'
  }
}))

const Error = () => {
  const classes = useErrorStyles()
  return (
    <div className={classes.errorBlock} data-testid="errorBlock--test">
      <div className={classes.errorBlockTop}>
        <CloudOffIcon className={classes.errorIcon} />
        Internal server error !
      </div>
      <div className={classes.errorBody}>
        Could not process the request.
        <br /> Please Make sure you are connected to VPN and try again.
      </div>
      <div className={classes.errorRetry}>
        <Button
          width="3rem"
          variant="contained"
          color="secondary"
          href={window.location.href}
        >
          Retry
        </Button>
      </div>
    </div>
  )
}

export default Error
