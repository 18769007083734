import React from 'react'
import { useTableRowStyles } from './TableStyles'
import { TableCell } from '@material-ui/core'
function EnhancedTableCell({ rowData, labelId }) {
  const classes = useTableRowStyles()
  const { cluster, name, id, namespace, userGroup } = rowData
  return (
    <>
      <TableCell
        align="center"
        key={`cluster-${id}`}
        className={classes.tablecell__name}
      >
        {cluster}
      </TableCell>
      <TableCell
        align="center"
        key={`namespace-${id}`}
        className={classes.tablecell__name}
      >
        {namespace}
      </TableCell>
      <TableCell
        align="center"
        key={`usergroup-${id}`}
        className={classes.tablecell__name}
      >
        {userGroup}
      </TableCell>
      <TableCell
        align="center"
        key={`name-${id}`}
        className={classes.tablecell__name}
        component="th"
        id={labelId}
        scope="row"
        padding="none"
        data-testid="tableCell__name--test"
      >
        {name}
      </TableCell>
    </>
  )
}

export default EnhancedTableCell
