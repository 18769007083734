import React from 'react'
import Chip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/core'
import Popover from '@material-ui/core/Popover'
const useStyles = makeStyles(theme => ({
  tablecell__chip: {
    margin: '0.2rem 0.2rem'
  },
  paper: {
    padding: theme.spacing(2),
    backgroundColor: '#1b213a',
    color: '#ffffff'
  },
  popover: {
    pointerEvents: 'none'
  },
  popover__heading: {
    fontSize: '1.2rem',
    fontWeight: 'bold'
  },
  popover__details: {
    display: 'flex',
    flexDirection: 'column'
  },
  popover__entryname: {
    display: 'inline-block',
    width: '4.5rem'
  },
  popover__status: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  popover__statusicon: {
    width: '0.8rem !important',
    height: '0.8rem !important'
  },
  configsList: {
    padding: '5px',
    marginLeft: '1rem'
  }
}))

function CofigsTableChip({ configKey, configValue, configs, name }) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  return (
    <React.Fragment>
      <Chip
        className={classes.tablecell__chip}
        data-testid="ConfigsTableChip--test"
        label={
          configKey +
          ' : ' +
          configValue +
          (Object.keys(configs).length > 1 ? ' . . . ' : '')
        }
        color="primary"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      <Popover
        data-testid="mouse-over-popover"
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <section className={classes.popover__heading}>Configs : {name}</section>
        <section className={classes.popover__details}>
          <ul>
            {Object.keys(configs)?.map((configKey, index) => (
              <li key={index} className={classes.configsList}>
                {configKey} : {configs[configKey]}
              </li>
            ))}
          </ul>
        </section>
      </Popover>
    </React.Fragment>
  )
}

export default CofigsTableChip
