import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Slider from '@material-ui/core/Slider'
import { sliderMarks } from '../constants'

const DiscreteSlider = withStyles(theme => ({
  root: {
    width: 230
  },
  thumb: {
    backgroundColor: theme.palette.secondary.main,
    height: 24,
    width: 24,
    marginTop: -8,
    marginLeft: -10
  },
  rail: {
    backgroundColor: theme.palette.secondary.main,
    height: 8,
    borderRadius: 10
  },
  track: {
    backgroundColor: theme.palette.secondary.main,
    height: 8,
    borderRadius: 10
  },
  mark: {
    display: 'none'
  },
  markLabel: {
    color: theme.palette.secondary.contrastText
  },
  valueLabel: {
    display: 'none'
  }
}))(Slider)

const LevelSlider = ({ value, handleChange }) => {
  return (
    <DiscreteSlider
      value={value}
      onChange={handleChange}
      step={25}
      marks={sliderMarks}
      data-testid="slider--test"
    />
  )
}

export default LevelSlider
