import React, { useState, useEffect } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TablePagination from '@material-ui/core/TablePagination'
import Paper from '@material-ui/core/Paper'
import ServiceTableRow from './ServiceTableRow'
import Loading from '../Loading'
import Axios from 'axios'
import EnhancedTableHead from '../components/EnhancedTableHead'
import EnhancedTableToolbar from '../components/EnhancedToolBar'
import { useStyles } from '../components/TableStyles'
import EnhancedFilter from '../components/EnhancedFilter'
import Error from '../Error'
import { useHistory } from 'react-router'
import { LEVEL_UPDATE } from '../constants'

const ServiceTable = () => {
  const [response, setResponse] = useState(
    JSON.parse(localStorage.getItem('accessCode'))
  )
  const classes = useStyles()
  const [isPageLoading, setPageLoading] = useState(true)
  const [tableData, setTableData] = useState(null)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('name')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [isServiceAdded, setServiceAdded] = useState(false)
  const [focus, setFocus] = useState(false)
  const [searchEnabled, setSearchEnabled] = useState(false)
  const [filterEnabled, setFilterEnabled] = useState(false)
  const [finalQueryData, setFinalQueryData] = useState({})
  const [firstRender, setFirstRender] = useState(true)
  const [error, isError] = useState(false)
  const [configs, setConfigs] = useState([])
  const [loading, isLoading] = useState(true)
  const history = useHistory()

  useEffect(async () => {
    if (!firstRender) {
      const tableData = await getTableData(finalQueryData)
      setTableData(() => tableData)
      setPageLoading(false)
      setFirstRender(false)
    }
  }, [isServiceAdded])

  const newRefreshApi = async isGetAll => {
    try {
      const newAccessToken = await Axios.post(
        `${LEVEL_UPDATE}/refresh`,
        { refreshToken: response?.refreshToken },
        { headers: { Authorization: `Bearer ${response?.accessToken}` } }
      )
      setResponse(prev => ({
        ...prev,
        accessToken: newAccessToken?.data?.data?.accessToken
      }))
      if (isGetAll) {
        const tokens = JSON.parse(localStorage.getItem('accessCode')) || {}
        tokens.accessToken = newAccessToken?.data?.data?.accessToken
        localStorage.setItem('accessCode', JSON.stringify(tokens))
        await Axios.get(`${LEVEL_UPDATE}/configs`, {
          headers: { Authorization: `Bearer ${tokens?.accessToken}` }
        })
      }
    } catch (err) {
      history.push('/logout')
    }
  }

  useEffect(async () => {
    try {
      await newRefreshApi(false)
      const configsResponse = await Axios.get(`${LEVEL_UPDATE}/configs`, {
        headers: { Authorization: `Bearer ${response?.accessToken}` }
      })
      setConfigs(configsResponse.data)
      isLoading(false)
      isError(false)
    } catch (err) {
      if (err?.response?.status === 401) {
        await newRefreshApi(true)
      }
      isError(true)
      isLoading(false)
    }
  }, [])

  useEffect(() => {
    Array.isArray(configs?.data) === false && (configs.data = [])
    const tableData = configs?.data?.map(config => getRowData(config))
    setTableData(() => tableData)
    setPageLoading(false)
    setFirstRender(false)
  }, [configs])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleClearFilterResults = async () => {
    try {
      setPageLoading(true)
      setSearchEnabled(false)
      const tableData = await getTableData('clearFilter')
      setTableData(() => tableData)
      setPageLoading(false)
      setFilterEnabled(false)
      setFinalQueryData({})
    } catch (err) {
      isError(true)
    }
  }

  const getFilterResults = async () => {
    if (Object.keys(finalQueryData).length > 0) {
      try {
        setPageLoading(true)
        setSearchEnabled(true)
        const tableData = await getTableData(finalQueryData)
        setTableData(() => tableData)
        setPageLoading(false)
      } catch (err) {
        isError(true)
      }
    }
  }

  const handleKeyPress = event => {
    if (event.key === 'Enter' && focus) {
      getFilterResults()
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const getRowData = service => {
    const retObj = {
      cluster: service?.cluster,
      id: service?.id,
      name: service?.serviceName,
      level: service?.config?.LOG_LEVEL,
      namespace: service?.namespace,
      versionKey: service?.versionKey,
      userGroup: service?.userGroup
    }
    return retObj
  }

  const getTableData = async params => {
    const queryParams = { ...params, like: true }

    const queryString = Object.entries(queryParams)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join('&&')

    const servicesResponse = await Axios.get(
      `${LEVEL_UPDATE}/configs${
        params === 'clearFilter' ? '' : `?${queryString}`
      }`,
      {
        headers: { Authorization: `Bearer ${response?.accessToken}` }
      }
    )

    const responses = servicesResponse?.data?.data?.map(service => {
      return {
        cluster: service?.cluster,
        id: service?.id,
        name: service?.serviceName,
        level: service?.config.LOG_LEVEL,
        namespace: service?.namespace,
        versionKey: service?.versionKey,
        userGroup: service?.userGroup
      }
    })
    return responses || []
  }

  const descendingComparator = (a, b, orderBy) => {
    if (!b[orderBy]) return -1
    if (b[orderBy]?.toLowerCase() < a[orderBy]?.toLowerCase()) {
      return -1
    }
    if (b[orderBy]?.toLowerCase() > a[orderBy]?.toLowerCase()) {
      return 1
    }
    return 0
  }

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }

  const getResults = (stabilizedThis, comparator) => {
    return stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })
  }

  const stableSort = (array, comparator) => {
    let stabilizedThis = array?.map((el, index) => [el, index])
    stabilizedThis = getResults(stabilizedThis, comparator)
    return stabilizedThis?.map(el => el[0])
  }

  return loading ? (
    <Loading />
  ) : error ? (
    <Error />
  ) : (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          handleServiceAdded={setServiceAdded}
          onRequestSearch={setSearchEnabled}
          searchEnabled={searchEnabled}
          tableData={tableData}
          setPageLoading={setPageLoading}
          handleFilter={setFilterEnabled}
          filterValue={filterEnabled}
          handleClearFilterResults={handleClearFilterResults}
          page="loglevel"
          response={response}
        />
        {filterEnabled ? (
          <EnhancedFilter
            setFinalQueryData={setFinalQueryData}
            finalQueryData={finalQueryData}
            setFocus={setFocus}
            handleKeyPress={handleKeyPress}
            getFilterResults={getFilterResults}
            searchEnabled={searchEnabled}
            handleClearFilterResults={handleClearFilterResults}
          />
        ) : (
          <></>
        )}
        {isPageLoading ? (
          <Loading />
        ) : (
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
              data-testid="serviceTable--test"
            >
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={tableData?.length}
                page="loglevel"
              />
              <TableBody>
                {stableSort(tableData, getComparator(order, orderBy))
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map(row => {
                    const labelId = `enhanced-table-checkbox-${row.id}`
                    return (
                      <ServiceTableRow
                        key={row.id}
                        rowData={row}
                        labelId={labelId}
                        handelUpdateService={setServiceAdded}
                      />
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {isPageLoading ? (
          <Loading />
        ) : (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={tableData?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className={`${classes.pagination} ${classes.iconRed}`}
          />
        )}
      </Paper>
    </div>
  )
}

export default ServiceTable
