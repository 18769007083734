import { useEffect } from 'react'
import { useHistory } from 'react-router'

export default function LogoutUIWrapper({ onSetResponse }) {
  const history = useHistory()
  useEffect(() => {
    localStorage.removeItem('accessCode')
    onSetResponse(JSON.parse(localStorage.getItem('accessCode')))
    history.push('/login')
  }, [])

  return null
}
