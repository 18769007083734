import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import MenuRoundedIcon from '@material-ui/icons/MenuRounded'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import styles from './Navbar.module.css'

const Navbar = () => {
  const [click, setClick] = useState(false)
  const handleClick = () => setClick(!click)

  return (
    <nav data-testid="navbar--test" className={styles.navbar}>
      <NavLink className={styles.navbar__title} to="/">
        <span className={styles['navbar__title--red']}>Health</span> Check
      </NavLink>
      <div
        data-testid="navbar__menu--test"
        className={styles.navbar__menu}
        onClick={handleClick}
      >
        <IconButton edge="start" color="inherit" aria-label="menu">
          {click ? (
            <CloseRoundedIcon data-testid="closeIcon--test" fontSize="large" />
          ) : (
            <MenuRoundedIcon data-testid="menuIcon--test" fontSize="large" />
          )}
        </IconButton>
      </div>
      <section
        data-testid="navbar__menubar--test"
        className={`${styles.navbar__buttonbar} ${click ? styles.active : ''} `}
      >
        <ul>
          <li data-testid="navbar-button__list--test">
            <NavLink
              activeStyle={{ backgroundColor: '#ff4385' }}
              className={styles.navbar__button}
              to="/servicelist"
            >
              Service List
            </NavLink>
          </li>
          <li data-testid="navbar-button__graph--test">
            <NavLink
              activeStyle={{ backgroundColor: '#ff4385' }}
              className={`${styles.navbar__button}`}
              to="/graph"
            >
              Graph
            </NavLink>
          </li>
          <li data-testid="navbar-button__loglevel--test">
            <NavLink
              activeStyle={{ backgroundColor: '#ff4385' }}
              className={styles.navbar__button}
              to="/loglevel"
            >
              Log Level
            </NavLink>
          </li>
          <li data-testid="navbar-button__configs--test">
            <NavLink
              activeStyle={{ backgroundColor: '#ff4385' }}
              className={styles.navbar__button}
              to="/configs"
            >
              Configs
            </NavLink>
          </li>
          <li data-testid="navbar-button__logout--test">
            <NavLink
              activeStyle={{ backgroundColor: '#ff4385' }}
              className={styles.navbar__button}
              to="/logout"
            >
              Logout
            </NavLink>
          </li>
        </ul>
      </section>
    </nav>
  )
}

export default Navbar
