import React from 'react'
import Chip from '@material-ui/core/Chip'
import Popover from '@material-ui/core/Popover'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  tablecell__statusicon: {
    width: '1rem !important',
    height: '1rem !important',
    marginLeft: '0.5rem !important',
    backgroundColor: `${theme.palette.unknown.main} !important`,
    borderRadius: '50%'
  },
  'tablecell__statusicon--up': {
    backgroundColor: `${theme.palette.success.main} !important`
  },
  'tablecell__statusicon--down': {
    backgroundColor: `${theme.palette.secondary.main} !important`
  },
  tablecell__chip: {
    margin: '0.2rem 0.2rem'
  },
  popover: {
    pointerEvents: 'none'
  },
  popover__heading: {
    fontSize: '1.2rem',
    fontWeight: 300
  },
  popover__details: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem 0rem'
  },
  popover__entryname: {
    display: 'inline-block',
    width: '4.5rem'
  },
  popover__status: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  popover__statusicon: {
    width: '0.8rem !important',
    height: '0.8rem !important'
  },
  paper: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.secondary.contrastText,
    color: '#000000'
  }
}))

const ServiceListChip = ({ name, status, host, database, url }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const getStatusClasses = (classes, status) => {
    const statusClasses = classes.tablecell__statusicon
    if (status === 'up')
      return statusClasses.concat(` ${classes['tablecell__statusicon--up']}`)
    if (status === 'down')
      return statusClasses.concat(` ${classes['tablecell__statusicon--down']}`)
    return statusClasses
  }

  const open = Boolean(anchorEl)

  return (
    <React.Fragment>
      <Chip
        className={classes.tablecell__chip}
        avatar={
          <span
            className={getStatusClasses(classes, status.toLowerCase())}
            data-testid="tablecell__status--test"
          ></span>
        }
        label={name}
        color="primary"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      <Popover
        data-testid="mouse-over-popover"
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <section className={classes.popover__heading}>{name}</section>
        <section className={classes.popover__details}>
          <span>
            <span className={classes.popover__entryname}>Status:</span>
            <span className={classes.popover__status}>
              {status}
              <span
                className={`${classes.popover__statusicon} ${getStatusClasses(
                  classes,
                  status.toLowerCase()
                )}`}
              ></span>
            </span>
          </span>
          {host && (
            <span>
              <span className={classes.popover__entryname}>Host:</span>
              {host}
            </span>
          )}
          {database && (
            <span>
              <span className={classes.popover__entryname}>Database:</span>
              {database}
            </span>
          )}
          {url && (
            <span>
              <span className={classes.popover__entryname}>Url:</span>
              {url}
            </span>
          )}
        </section>
      </Popover>
    </React.Fragment>
  )
}

export default ServiceListChip
