import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(theme => ({
  root: {
    margin: 'auto',
    padding: '1rem 0',
    width: '95%',
    color: theme.palette.secondary.contrastText
  },
  tablecell__name: {
    color: theme.palette.secondary.contrastText
  },
  searchbar: {
    minWidth: '200px',
    backgroundColor: 'red',
    padding: '10px'
  },
  paper: {
    backgroundColor: theme.palette.primary.light,
    width: '100%',
    marginBottom: theme.spacing(2),
    boxShadow: 'none'
  },
  table: {
    minWidth: 750,
    padding: 0,
    margin: 0,
    backgroundColor: theme.palette.primary.light
  },
  tableHead: {
    color: theme.palette.secondary.contrastText,
    fontWeight: 700,

    fontSize: '1.2rem'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  pagination: {
    color: theme.palette.secondary.contrastText
  },
  iconRed: {
    '& svg': {
      fill: theme.palette.secondary.main
    }
  },
  searchBar: {
    padding: '10px',
    display: 'flex',
    justifyContent: 'space-evenly',
    '@media (max-width: 780px)': {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  searchButton: {
    backgroundColor: theme.palette.secondary.main,
    '@media (min-width: 800px)': {
      paddingLeft: '10px',
      paddingRight: '10px',
      width: '40%',
      display: 'flex',
      justifyContent: 'space-evenly'
    }
  },
  clearButton: {
    backgroundColor: theme.palette.secondary.main,
    '@media (max-width:800px)': {
      margin: '10px'
    },
    '@media (min-width: 800px)': {
      paddingLeft: '10px',
      marginLeft: '10px',
      paddingRight: '10px',
      width: '40%',
      display: 'flex',
      justifyContent: 'space-evenly'
    }
  },
  editIcon: {
    cursor: 'pointer'
  }
}))
export const useTableRowStyles = makeStyles(theme => ({
  deleteConfirmation: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
    marginTop: '0.5rem'
  },
  action_icons: { display: 'flex', gap: '1rem', justifyContent: 'center' },
  deleteEditIcon: {
    cursor: 'pointer',
    borderRadius: '50%',
    padding: '2px',
    '&:hover': { backgroundColor: '#262b48' }
  },
  tablecell__name: {
    color: theme.palette.secondary.contrastText,
    fontSize: '16px',
    padding: '1rem'
  },
  tablecell__slider: {
    color: theme.palette.secondary.contrastText,
    fontSize: '16px',
    width: '35%'
  },
  tablecell__icons: { display: 'flex', justifyContent: 'space-evenly' },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'auto'
  },
  modal__title: {
    marginBottom: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  modal__body: {
    textAlign: 'center'
  },
  modal__halfinput: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center'
  },
  modal__input: {
    marginBottom: '1rem',
    width: '100%',
    '&:disabled': {
      pointerEvents: 'auto !important',
      cursor: 'not-allowed'
    }
  },
  modal__addConfigsinput: {
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  'modal__input--half': {
    width: '48%'
  },
  'modal__configs__input--half': {
    width: '45%'
  },
  modal__logger: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '1rem',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row'
    },
    marginBottom: '1.5rem'
  },
  modal__subheading: {
    fontSize: '1.3rem',
    textAlign: 'left',
    marginBottom: '1rem',
    fontWeight: 100
  },
  modal__success: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.success.main,
    fontSize: '1rem',
    fontWeight: 700
  },
  modal__error: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.secondary.main,
    fontSize: '1rem',
    fontWeight: 700
  },
  modal__erroricon: {
    marginRight: '0.5rem'
  },

  modal__successicon: {
    marginRight: '0.5rem'
  },
  paper: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(2, 4, 3),
    outline: 'none',
    width: '30rem',
    margin: '0rem 1rem'
  },
  toolbar__addService: {
    whiteSpace: 'nowrap'
  },
  addConfigs: {
    backgroundColor: 'red',
    borderRadius: '50px'
  },
  tablecell__configsOptions: {
    display: 'flex'
  },
  editConfigs: {
    backgroundColor: 'white',
    borderRadius: '50px',
    padding: '3px',
    color: 'red',
    marginRight: '10px',
    cursor: 'pointer'
  },
  configsAdd: {
    border: '1px solid green',
    borderRadius: '50px',
    marginBottom: '1rem',
    cursor: 'pointer'
  },
  configsRemove: {
    border: '1px solid red',
    borderRadius: '50px',
    marginBottom: '1rem',
    cursor: 'pointer'
  },
  emptyContainer: {
    width: '20px'
  },
  emptyConfigsText: {
    fontSize: '20px',
    color: 'red'
  },
  tablecell__edit: {
    width: '5%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}))
export const useToolbarStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row'
    }
  },
  title: {
    fontWeight: 500,
    color: theme.palette.secondary.contrastText,
    flex: '1 3 100%'
  },
  searchbar: {
    marginLeft: '20px',
    marginRight: '20px',
    border: '1px solid white',
    borderRadius: '10px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    cursor: 'pointer',
    width: '100%',
    '@media (max-width: 780px)': {
      margin: '10px'
    }
  },
  searchtext: {
    width: '85%',
    marginLeft: '10px'
  },
  searchicon: {
    marginLeft: '7px',
    marginRight: '5px'
  },
  enhancedsearchbar: {
    display: 'flex',
    marginLeft: '10px',
    marginRight: '10px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    cursor: 'pointer'
  },
  filterList: {
    '@media (min-width: 780px)': {
      flex: '1 1 7rem',
      display: 'flex',
      justifyContent: 'space-evenly',
      backgroundColor: theme.palette.secondary.main,
      padding: '5px',
      margin: '10px',
      borderRadius: '5px',
      cursor: 'pointer',
      whiteSpace: 'nowrap'
    },
    margin: '10px',
    paddingLeft: '1.9rem',
    paddingRight: '1.9rem'
  },
  searchfilter: {
    display: 'flex',
    flexWrap: 'wrap',
    cursor: 'pointer',
    marginTop: '5px',
    marginLeft: '15px'
  },
  searchfilteritem: {
    backgroundColor: '#BEBEBE',
    borderRadius: '10px',
    padding: '5px',
    margin: '5px',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '12px',
    color: 'black'
  },
  removeicon: {
    fontSize: '20px',
    color: 'red',
    marginLeft: '10px'
  },
  cancelIcon: {
    color: 'white',
    backgroundColor: 'red',
    borderRadius: '5px',
    margin: '10px'
  },
  searchdrDropDown: {
    backgroundColor: '#f50057',
    borderRadius: '10px 1px 1px 10px',
    padding: '5px',
    marginRight: '5px'
  },
  searchAdd: {
    color: 'white',
    backgroundColor: 'green',
    borderRadius: '50px',
    marginRight: '5px'
  }
}))
