import React, { useEffect } from 'react'
import { useHistory, Route, Redirect } from 'react-router-dom'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { response } = { ...rest }
  const history = useHistory()
  const token = JSON.parse(localStorage.getItem('accessCode'))

  useEffect(() => {
    if (!token || response?.accessCode !== token?.accessCode) {
      history.push('/logout')
    }
  }, [token, history])

  return token ? (
    <Route {...rest}>
      <Component {...rest} />
    </Route>
  ) : (
    <Redirect to="/logout" />
  )
}
export default PrivateRoute
