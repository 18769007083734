import { createTheme } from '@material-ui/core/styles'
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 400
    }
  },
  palette: {
    type: 'dark',
    background: {
      default: '#1b213a'
    },
    primary: {
      light: '#262b48',
      main: '#1b213a'
    },
    secondary: {
      light: '#ff6b87',
      main: '#f50057',
      dark: '#f62950',
      contrastText: '#ffffff'
    },
    success: {
      light: '#81c784',
      main: '#4caf50',
      dark: '#388e3c',
      contrastText: '#ffffff'
    },
    unknown: {
      main: '#f4b33e',
      light: '#e8de55'
    }
  }
})

export default theme
