import React, { useState } from 'react'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import { Button } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Axios from 'axios'
import CancelIcon from '@material-ui/icons/Cancel'
import CloseRoundedIcon from '@material-ui/icons/Close'
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded'
import IconButton from '@material-ui/core/IconButton'
import { LoadingRoller } from '../LoadingRoller'
import LevelSlider from '../logging/LevelSlider'
import { LEVEL_UPDATE, valueToLevelMap } from '../constants'
import {
  EnhancedConfigsEditField,
  EnhancedConfigsEntryField
} from '../configs/ConfigsTableRow'
import { useTableRowStyles } from './TableStyles'
import { useHistory } from 'react-router'

const TableModal = ({ tableData, handleServiceAdded, page }) => {
  const [response, setResponse] = useState(
    JSON.parse(localStorage.getItem('accessCode'))
  )
  const classes = useTableRowStyles()
  const [open, setOpen] = useState(false)
  const [nameValidated, setNameValidation] = useState('initial')
  const [nameSpaceValidated, setNameSpaceValidation] = useState('initial')
  const [clusterValidated, setClusterValidation] = useState('initial')
  const [userGroupValidated, setUserGroupValidation] = useState('initial')

  const [serviceName, setServiceName] = useState('')
  const [userGroup, setUserGroup] = useState('')
  const [nameSpace, setNameSpace] = useState('')
  const [level, setLevel] = useState(50)
  const [cluster, setCluster] = useState('')
  const [status, setStatus] = useState('')

  const [addConfigsList, setAddConfigsList] = useState({})
  const [remainingKey, setRemainingKey] = useState('')
  const [remainingValue, setRemainingValue] = useState('')
  const history = useHistory()

  const handleOpen = () => {
    setStatus('')
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
    setServiceName('')
    setCluster('')
    setNameSpace('')
  }
  const handleNameChange = value => {
    setServiceName(value)
    value === '' ? setNameValidation('empty') : setNameValidation('filled')
  }
  const handleUserGroupNameChange = value => {
    setUserGroup(value)
    value === ''
      ? setUserGroupValidation('empty')
      : setUserGroupValidation('filled')
  }
  const handleClusterChange = value => {
    setCluster(value)
    value === ''
      ? setClusterValidation('empty')
      : setClusterValidation('filled')
  }
  const handleNameSpaceChange = value => {
    setNameSpace(value)
    value === ''
      ? setNameSpaceValidation('empty')
      : setNameSpaceValidation('filled')
  }
  const handleSubmit = async e => {
    e.preventDefault()
    if (nameValidated === 'initial') setNameValidation('empty')
    if (nameSpaceValidated === 'initial') setNameSpaceValidation('empty')
    if (clusterValidated === 'initial') setClusterValidation('empty')
    if (userGroupValidated === 'initial') setUserGroupValidation('empty')
    if (
      nameValidated === 'filled' &&
      nameSpaceValidated === 'filled' &&
      clusterValidated === 'filled' &&
      userGroupValidated === 'filled'
    ) {
      const request =
        page === 'loglevel'
          ? {
              serviceName: serviceName,
              cluster: cluster,
              config: { LOG_LEVEL: valueToLevelMap[level] },
              namespace: nameSpace,
              userGroup: userGroup
            }
          : {
              serviceName: serviceName,
              cluster: cluster,
              config: addConfigsList,
              namespace: nameSpace,
              userGroup: userGroup
            }
      setStatus('loading')
      await createNewService(
        request,
        JSON.parse(localStorage.getItem('accessCode'))?.accessToken
      )
    }
  }

  const refreshApi = async body => {
    try {
      const newAccessToken = await Axios.post(
        `${LEVEL_UPDATE}/refresh`,
        { refreshToken: response?.refreshToken },
        { headers: { Authorization: `Bearer ${response?.accessToken}` } }
      )
      setResponse(prev => ({
        ...prev,
        accessToken: newAccessToken?.data?.data?.accessToken
      }))
      const tokens = JSON.parse(localStorage.getItem('accessCode')) || {}
      tokens.accessToken = newAccessToken?.data?.data?.accessToken
      localStorage.setItem('accessCode', JSON.stringify(tokens))

      await createNewService(body, newAccessToken?.data?.data?.accessToken)
    } catch (err) {
      history.push('/logout')
    }
  }

  const createNewService = async (body, authKey) => {
    let isStatusSuccess = false
    try {
      const res = await Axios.post(`${LEVEL_UPDATE}/configs`, body, {
        headers: { Authorization: `Bearer ${authKey}` }
      })

      if (res?.status === 200) setStatus('this service is already exist')
      else if (res?.status === 201) {
        isStatusSuccess = true
        setStatus('success')
      }
      handleServiceAdded(prev => !prev)
    } catch (err) {
      if (isStatusSuccess) setStatus('success')
      else if (err?.response?.status === 403) {
        setStatus('User does not has necessary permission for this userGroup')
      } else if (err?.response?.status === 500) {
        setStatus('Internal Server Error')
      } else if (err?.response?.status === undefined) {
        setStatus('this service is already exist')
      } else if (err?.response?.status === 401) {
        await refreshApi(body)
      } else {
        setStatus('Could not add service')
      }
    }
    setTimeout(() => setStatus(''), 1500)
  }

  return (
    <div>
      <Button
        color="secondary"
        variant="contained"
        className={classes.toolbar__addService}
        onClick={handleOpen}
        data-testid="servicemodal__button--test"
        disabled={tableData?.length < 0}
      >
        Add service
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
        data-testid="servicemodal--test"
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 className={classes.modal__title} id="transition-modal-title">
              <span>Add new service</span>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => handleClose()}
                data-testid="modal__close--test"
              >
                <CloseRoundedIcon color="secondary" fontSize="large" />
              </IconButton>
            </h2>
            <section id="transition-modal-description">
              <form
                autoComplete="off"
                className={classes.modal__body}
                onSubmit={e => handleSubmit(e)}
                data-testid="servicemodal__form--test"
              >
                <div className={classes.modal__subheading}>
                  Service Details :
                </div>
                <section className={classes.modal__halfinput}>
                  <TextField
                    variant="outlined"
                    error={nameValidated === 'empty'}
                    helperText={
                      nameValidated === 'empty'
                        ? 'service name is required'
                        : ''
                    }
                    className={`${classes.modal__input} ${classes['modal__input--half']}`}
                    id="standard-required-name"
                    type="text"
                    label="Service Name*"
                    color="secondary"
                    value={serviceName}
                    onChange={e => handleNameChange(e.target.value)}
                  />
                  <TextField
                    variant="outlined"
                    error={userGroupValidated === 'empty'}
                    helperText={
                      userGroupValidated === 'empty'
                        ? 'user group is required'
                        : ''
                    }
                    className={`${classes.modal__input} ${classes['modal__input--half']}`}
                    id="user-group-required-name"
                    type="text"
                    label="User Group*"
                    color="secondary"
                    value={userGroup}
                    onChange={e => handleUserGroupNameChange(e.target.value)}
                  />
                </section>
                <section className={classes.modal__halfinput}>
                  <TextField
                    variant="outlined"
                    error={nameSpaceValidated === 'empty'}
                    helperText={
                      nameSpaceValidated === 'empty'
                        ? 'Namespace is required'
                        : ''
                    }
                    className={`${classes.modal__input} ${classes['modal__input--half']}`}
                    id="standard-required-owner"
                    type="text"
                    label="NameSpace*"
                    color="secondary"
                    value={nameSpace}
                    onChange={e => handleNameSpaceChange(e.target.value)}
                  />
                  <TextField
                    variant="outlined"
                    error={clusterValidated === 'empty'}
                    helperText={
                      clusterValidated === 'empty' ? 'Cluster is required' : ''
                    }
                    className={`${classes.modal__input} ${classes['modal__input--half']}`}
                    id="standard-required-cluster"
                    type="text"
                    label="Cluster*"
                    color="secondary"
                    value={cluster}
                    onChange={e => handleClusterChange(e.target.value)}
                  />
                </section>
                {page === 'loglevel' ? (
                  <section className={classes.modal__logger}>
                    <span>Log Level :</span>
                    <LevelSlider
                      value={level}
                      handleChange={(e, val) => setLevel(val)}
                    />
                  </section>
                ) : (
                  <>
                    <div className={classes.modal__subheading}>Configs:</div>
                    <section className={classes.modal__configInput}>
                      <EnhancedConfigsEntryField
                        handleConfigsList={setAddConfigsList}
                        configsList={addConfigsList}
                        remainingKey={remainingKey}
                        remainingValue={remainingValue}
                        setRemainingKey={setRemainingKey}
                        setRemainingValue={setRemainingValue}
                      />
                    </section>
                    {Object.keys(addConfigsList)
                      .filter(config => config !== remainingKey)
                      .map((configNames, index) => (
                        <EnhancedConfigsEditField
                          key={index}
                          configsList={addConfigsList}
                          configNames={configNames}
                          handleConfigsList={setAddConfigsList}
                        />
                      ))}
                  </>
                )}
                {status === 'loading' ? (
                  <LoadingRoller />
                ) : status === 'success' ? (
                  <div className={classes.modal__success}>
                    <CheckCircleRoundedIcon
                      className={classes.modal__successicon}
                    />
                    {page === 'loglevel'
                      ? 'Service added successfully'
                      : 'Configs added successfully'}
                  </div>
                ) : status === '' ? (
                  <Button color="secondary" variant="contained" type="submit">
                    Submit
                  </Button>
                ) : (
                  <div className={classes.modal__error}>
                    <CancelIcon
                      color="secondary"
                      className={classes.modal__erroricon}
                    />
                    {status}
                  </div>
                )}
              </form>
            </section>
          </div>
        </Fade>
      </Modal>
    </div>
  )
}
export default TableModal
