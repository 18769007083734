import React from 'react'
import { Button } from '@material-ui/core'
import { Search, Clear } from '@material-ui/icons'
import { useStyles } from './TableStyles'
import EnhancedSearchbar from './EnhanceSearchBar'
function EnhancedFilter({
  setFinalQueryData,
  finalQueryData,
  setFocus,
  handleKeyPress,
  getFilterResults,
  searchEnabled,
  handleClearFilterResults
}) {
  const classes = useStyles()
  return (
    <div
      className={classes.searchBar}
      onFocus={() => setFocus(true)}
      onKeyDown={handleKeyPress}
      data-testid="filterSearch--test"
    >
      <EnhancedSearchbar
        type={'cluster'}
        handleQueryData={setFinalQueryData}
        finalQueryData={finalQueryData}
      />
      <EnhancedSearchbar
        type={'namespace'}
        handleQueryData={setFinalQueryData}
        finalQueryData={finalQueryData}
      />
      <EnhancedSearchbar
        type={'userGroup'}
        handleQueryData={setFinalQueryData}
        finalQueryData={finalQueryData}
      />
      <EnhancedSearchbar
        type={'serviceName'}
        handleQueryData={setFinalQueryData}
        finalQueryData={finalQueryData}
      />
      <Button className={classes.searchButton} onClick={getFilterResults}>
        Search <Search />
      </Button>

      <Button
        color="secondary"
        variant="contained"
        className={classes.clearButton}
        onClick={() => handleClearFilterResults()}
        data-testid={'clearFilterButton--test'}
        disabled={!searchEnabled}
      >
        Clear
        <Clear />
      </Button>
    </div>
  )
}

export default EnhancedFilter
