import React from 'react'
import TableHead from '@material-ui/core/TableHead'
import { TableRow, TableCell, TableSortLabel } from '@material-ui/core'
const configsHeadCells = [
  {
    id: 'cluster',
    numeric: false,
    label: 'Cluster'
  },

  {
    id: 'namespace',
    numeric: false,
    disablePadding: false,
    label: 'Namespace'
  },
  {
    id: 'usergroup',
    numeric: false,
    disablePadding: false,
    label: 'UserGroup'
  },
  { id: 'name', numeric: false, disablePadding: true, label: 'Service Name' },
  { id: 'configs', numeric: false, disablePadding: false, label: 'Configs' },
  { id: 'Action', numeric: false, disablePadding: true, label: 'Action' }
]
const logLevelHeadcells = [
  {
    id: 'cluster',
    numeric: false,
    label: 'Cluster'
  },
  {
    id: 'namespace',
    numeric: false,
    disablePadding: false,
    label: 'Namespace'
  },
  {
    id: 'usergroup',
    numeric: false,
    disablePadding: false,
    label: 'User Group'
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Service Name'
  },
  { id: 'level', numeric: false, disablePadding: false, label: 'Log Level' },
  { id: 'Action', numeric: false, disablePadding: false, label: 'Action' }
]

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }
  const headcells =
    props.page === 'loglevel' ? logLevelHeadcells : configsHeadCells
  return (
    <TableHead>
      <TableRow data-testid="tableRowHead--test">
        {headcells.map(headCell => (
          <TableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              className={classes.iconRed}
            >
              <span className={classes.tableHead}>{headCell.label}</span>
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}
export default EnhancedTableHead
