import React from 'react'
import { TextField } from '@material-ui/core'
import { useToolbarStyles } from './TableStyles'
const EnhancedSearchbar = ({ type, handleQueryData, finalQueryData }) => {
  const classes = useToolbarStyles()
  const handleSearchText = async event => {
    if (event.target.value === '') {
      const updated = finalQueryData
      delete updated[type]
      await handleQueryData({
        ...finalQueryData,
        ...updated
      })
    } else {
      await handleQueryData(prevState => ({
        ...prevState,
        [type]: event.target.value
      }))
    }
  }

  return (
    <div className={classes.searchbar} data-testid="filterSearchBox--test">
      <TextField
        className={classes.searchtext}
        InputProps={{ disableUnderline: true }}
        onChange={handleSearchText}
        placeholder={'Search By ' + type}
        index={'Search By ' + type}
        value={finalQueryData[type]}
        type="text"
        data-testid="filterSearchBoxText--test"
      />
    </div>
  )
}
export default EnhancedSearchbar
