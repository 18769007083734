import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import ServiceListRow from './ServiceListRow'
import Loading from '../Loading'
import { useStyles } from '../components/TableStyles'
import axios from 'axios'
import Error from '../Error'
import { useHistory } from 'react-router'
import { HEALTH_CHECK_URL, LEVEL_UPDATE } from '../constants'
const headCells = [
  // { id: 'owner', numeric: false, disablePadding: true, label: 'Owner' },
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  {
    id: 'framework',
    numeric: false,
    disablePadding: false,
    label: 'Framework'
  },
  {
    id: 'version',
    numeric: false,
    disablePadding: false,
    label: 'App Version'
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status'
  },
  {
    id: 'lastchecked',
    numeric: false,
    disablePadding: false,
    label: 'Last Checked'
  },
  {
    id: 'lastlatency',
    numeric: false,
    disablePadding: true,
    label: 'Last Latency (ms)'
  },
  {
    id: 'databases',
    numeric: false,
    disablePadding: true,
    label: 'Databases'
  },
  {
    id: 'services',
    numeric: false,
    disablePadding: true,
    label: 'Services'
  }
]

function EnhancedTableHead(props) {
  const { classes } = props
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? 'none' : 'default'}
          >
            <span className={classes.tableHead}>{headCell.label}</span>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const useToolbarStyles = makeStyles(theme => ({
  root: { padding: 0 },
  title: { fontWeight: 500, color: theme.palette.secondary.contrastText }
}))

const EnhancedTableToolbar = () => {
  const classes = useToolbarStyles()
  return (
    <Toolbar className={classes.root}>
      <Typography
        className={classes.title}
        variant="h5"
        id="tableTitle"
        component="div"
      >
        Service List
      </Typography>
    </Toolbar>
  )
}

const ServiceList = () => {
  const [services, setServices] = useState(null)
  const classes = useStyles()
  const [error, isError] = useState(false)
  const [loading, isLoading] = useState(true)
  const [tableData, setTableData] = useState(null)
  const [response, setResponse] = useState(
    JSON.parse(localStorage.getItem('accessCode'))
  )
  const history = useHistory()
  const refreshApi = async isGetAll => {
    try {
      const newAccessToken = await axios.post(
        `${LEVEL_UPDATE}/refresh`,
        { refreshToken: response?.refreshToken },
        { headers: { Authorization: `Bearer ${response?.accessToken}` } }
      )
      setResponse(prev => ({
        ...prev,
        accessToken: newAccessToken?.data?.data?.accessToken
      }))
      if (isGetAll) {
        const tokens = JSON.parse(localStorage.getItem('accessCode')) || {}
        tokens.accessToken = newAccessToken?.data?.data?.accessToken
        localStorage.setItem('accessCode', JSON.stringify(tokens))
        await axios.get(HEALTH_CHECK_URL, {
          headers: { Authorization: `Bearer ${tokens?.accessToken}` }
        })
      }
    } catch (err) {
      history.push('/logout')
    }
  }

  useEffect(() => {
    const tableData = services?.map(service => getRowData(service))
    setTableData(() => tableData)
  }, [services])

  useEffect(async () => {
    try {
      await refreshApi(false)
      const healthCheckResponse = await axios.get(HEALTH_CHECK_URL, {
        headers: { Authorization: `Bearer ${response?.accessToken}` }
      })
      setServices(healthCheckResponse.data.data)
      isLoading(false)
      isError(false)
    } catch (err) {
      if (err?.response?.status === 401) {
        await refreshApi(true)
      }
      isError(true)
      isLoading(false)
    }
  }, [])

  const getRowData = service => {
    const retObj = {
      // owner: service.owner,
      name: service.name,
      url: service.url,
      status: service.status,
      lastchecked: service.lastCheck?.time,
      lastlatency: service.lastCheck?.latency,
      framework: service.lastCheck?.response?.details?.app?.framework,
      version: service.lastCheck?.response?.details?.app?.version,
      databases: service.lastCheck?.response?.details?.databases,
      services: service.lastCheck?.response?.details?.services
    }
    return retObj
  }

  return loading ? (
    <Loading />
  ) : error ? (
    <Error />
  ) : (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
            data-testid="servicelist--test"
          >
            <EnhancedTableHead classes={classes} rowCount={tableData?.length} />
            <TableBody>
              {tableData?.map((row, index) => {
                return (
                  <ServiceListRow
                    key={index}
                    id={`servicelistrow-${index}`}
                    rowData={row}
                  />
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  )
}

export default ServiceList
