import React, { Suspense } from 'react'
import { AppBar, Grid, Typography } from '@material-ui/core'
import ZopSmartLogo from '../assets/zopsmart.svg'
import styles from '../Navbar.module.css'
import './login.css'
const LoginButton = React.lazy(() => import('./loginButton'))

export function LoginPage({ onSetResponse }) {
  return (
    <>
      <AppBar position="fixed" style={{ boxShadow: 'none', padding: '20px' }}>
        <div className={styles.navbar__title}>
          <span className={styles['navbar__title--red']}>Health</span> Check
        </div>
      </AppBar>

      <Grid container justifyContent="center" alignItems="center">
        !response && (
        <div className="login-container">
          <div className="login-zopsmart-logo">
            <img src={`${ZopSmartLogo}`} alt="ZopSmart" />
            <span>Zop</span>
            <span className="login-smart">Smart</span>
          </div>
          <div className="form-box">
            <Typography variant="h5">
              Health-Check service allows to override configs during runtime,
              and a central dashboard to monitor the health-check of services.
            </Typography>
            <Suspense fallback={null}>
              <LoginButton onSetResponse={onSetResponse} />
            </Suspense>
          </div>
        </div>
        )
      </Grid>
    </>
  )
}
