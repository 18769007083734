export const sliderMarks = [
  {
    value: 0,
    label: 'DEBUG'
  },
  {
    value: 25,
    label: 'INFO'
  },
  {
    value: 50,
    label: 'WARN'
  },
  {
    value: 75,
    label: 'ERROR'
  },
  {
    value: 100,
    label: 'FATAL'
  }
]

export const valueToLevelMap = {
  0: 'DEBUG',
  25: 'INFO',
  50: 'WARN',
  75: 'ERROR',
  100: 'FATAL'
}

export const levelToValueMap = {
  DEBUG: 0,
  INFO: 25,
  WARN: 50,
  ERROR: 75,
  FATAL: 100
}
export const HEALTH_CHECK_URL =
  window.env?.REACT_APP_HEALTH_CHECK_URL ||
  process.env.REACT_APP_HEALTH_CHECK_URL
export const LEVEL_UPDATE =
  window.env?.REACT_APP_LEVEL_UPDATE || process.env.REACT_APP_LEVEL_UPDATE
export const CLIENT_ID =
  window.env?.REACT_APP_CLIENT_ID || process.env.REACT_APP_CLIENT_ID
export const REFRESH_ENDPOINT = '/refresh'
export const LOGIN_ENDPOINT = '/login'
