import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import LevelSlider from './LevelSlider'
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded'
import { levelToValueMap } from '../constants'
import CancelIcon from '@material-ui/icons/Cancel'

const useStyles = makeStyles(theme => ({
  slider__block: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  slider__buttonbar: {
    marginLeft: '1.6rem',
    display: 'flex',
    gap: '0.5rem',
    '&:hover': { cursor: 'pointer' }
  },
  slider__save: {
    height: '2rem',
    border: 0,
    borderRadius: '5px'
  },
  slider__cancel: {
    color: 'white',
    padding: 0
  },

  paper: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(2, 4, 3),
    outline: 'none',
    width: '25rem',
    margin: '0rem 1rem'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'auto'
  },
  modal__title: {
    marginBottom: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  modal__body: {
    textAlign: 'center'
  },
  modal__subheading: {
    fontSize: '1rem',
    textAlign: 'left',
    marginBottom: '1rem',
    fontWeight: 400
  },
  modal__input: {
    marginBottom: '1rem',
    width: '100%'
  },
  modal__success: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.success.main,
    fontSize: '1rem',
    fontWeight: 700
  },
  modal__error: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.secondary.main,
    fontSize: '1rem',
    fontWeight: 700
  },
  modal__erroricon: {
    marginRight: '0.5rem'
  },
  modal__successicon: {
    marginRight: '0.5rem'
  },
  modal__chip: {
    margin: '0 0.4rem'
  }
}))

const ServiceTableSlider = ({
  defaultLevel,
  status,
  handleStatusChange,
  handleLogChange
}) => {
  const classes = useStyles()
  const [sliderChanged, setSliderChanged] = useState(false)
  const [sliderValue, setSliderValue] = useState(levelToValueMap[defaultLevel])

  useEffect(() => {
    if (
      status === 'Unauthorised' ||
      status === 'Internal Server Error' ||
      status === 'Could not update service'
    ) {
      setSliderValue(levelToValueMap[defaultLevel])
    }
  }, [status])

  const handleLogChangeSubmit = async e => {
    e.preventDefault()
    setSliderValue(sliderValue)
    const authKey = JSON.parse(localStorage.getItem('accessCode'))
    await handleLogChange(
      getKeyByValue(levelToValueMap, sliderValue),
      authKey?.accessCode
    )
    setSliderChanged(false)
  }

  const handleSliderChange = (_e, value) => {
    handleStatusChange('')
    setSliderValue(value)
    setSliderChanged(value !== levelToValueMap[defaultLevel])
  }

  const getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value)
  }

  return (
    <div className={classes.slider__block}>
      <LevelSlider value={sliderValue} handleChange={handleSliderChange} />
      <div className={classes.slider__buttonbar}>
        {sliderChanged && (
          <Button
            data-testid="slider__save--test"
            className={classes.slider__save}
            variant="contained"
            color="secondary"
            onClick={handleLogChangeSubmit}
            disabled={status === 'loading'}
          >
            Save
          </Button>
        )}
        {status === 'success' && (
          <div className={classes.modal__success}>
            <CheckCircleRoundedIcon className={classes.modal__successicon} />
          </div>
        )}
        {status !== 'success' && status !== 'loading' && status !== '' && (
          <div className={classes.modal__error}>
            <CancelIcon
              color="secondary"
              className={classes.modal__erroricon}
            />
            {status}
          </div>
        )}
      </div>
    </div>
  )
}

export default ServiceTableSlider
