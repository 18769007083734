import React from 'react'
import './LoadingRoller.css'

export const LoadingRoller = () => {
  return (
    <div className="loading__spinner" data-testid="loading__spinner--test">
      <div className="loading__cirlce">
        <div></div>
      </div>
    </div>
  )
}
