import React, { useState } from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import ServiceTableSlider from './ServiceTableSlider'
import { Delete, Edit } from '@material-ui/icons'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import { Button } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import CancelIcon from '@material-ui/icons/Cancel'
import CloseRoundedIcon from '@material-ui/icons/Close'
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded'
import IconButton from '@material-ui/core/IconButton'
import { LoadingRoller } from '../LoadingRoller'
import Axios from 'axios'
import { useTableRowStyles } from '../components/TableStyles'
import EnhancedTableCell from '../components/TableCell'
import { useHistory } from 'react-router'
import { LEVEL_UPDATE } from '../constants'

const ServiceTableRow = ({ rowData, labelId, handelUpdateService }) => {
  const classes = useTableRowStyles()
  const [requestStatus, setRequestStatus] = useState('')
  const { cluster, name, id, level, versionKey, namespace } = rowData
  const [open, setOpen] = useState(false)
  const [updatedNamespace, setUpdatedNamespace] = useState(namespace)
  const [updatedServiceName, setUpdatedServiceName] = useState(name)
  const [updatedCluster, setUpdatedCluster] = useState(cluster)
  const [status, setStatus] = useState('')
  const [authKey, setAuthKey] = useState('')
  const [response, setResponse] = useState(
    JSON.parse(localStorage.getItem('accessCode'))
  )
  const [deleteModal, setDeleteModal] = useState(false)
  const history = useHistory()

  const handleClose = () => {
    setOpen(false)
    setAuthKey('')
  }

  const handleOpen = () => {
    setStatus('')
    setOpen(true)
  }
  const handleOpenDeleteModal = () => {
    setStatus('')
    setDeleteModal(true)
  }
  const setLogLevel = async (body, sliderLevel) => {
    const { cluster, id, name, versionKey, namespace } = body
    setRequestStatus('loading')
    try {
      await Axios.put(
        `${LEVEL_UPDATE}/configs/${id}`,
        {
          serviceName: name,
          cluster: cluster,
          config: { LOG_LEVEL: sliderLevel },
          versionKey: versionKey,
          namespace: namespace
        },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem('accessCode'))?.accessToken
            }`
          }
        }
      )
      setRequestStatus('success')
      handelUpdateService(prev => !prev)
    } catch (err) {
      if (err?.response?.status === 401) {
        await refreshApi(body, sliderLevel)
      } else if (err?.response?.status === 403) {
        setRequestStatus('Unauthorised')
      } else if (err?.response?.status === 500) {
        setRequestStatus('Internal Server Error')
      } else {
        setRequestStatus('Could not update service')
      }
    }
    setTimeout(() => setRequestStatus(''), 1500)
  }

  const handleChange = async (sliderLevel, authKey) => {
    const body = { cluster, id, name, versionKey, namespace }
    await setLogLevel(body, sliderLevel)
  }
  const handleSubmit = async e => {
    e.preventDefault()
    const request = {
      serviceName: updatedServiceName,
      cluster: updatedCluster,
      config: { LOG_LEVEL: level },
      versionKey: versionKey,
      namespace: updatedNamespace
    }
    setStatus('loading')
    await createNewService(request, authKey)
  }

  const refreshApi = async (body, sliderLevel) => {
    try {
      const newAccessToken = await Axios.post(
        `${LEVEL_UPDATE}/refresh`,
        { refreshToken: response?.refreshToken },
        { headers: { Authorization: `Bearer ${response?.accessToken}` } }
      )
      setResponse(prev => ({
        ...prev,
        accessToken: newAccessToken?.data?.data?.accessToken
      }))
      const tokens = JSON.parse(localStorage.getItem('accessCode')) || {}
      tokens.accessToken = newAccessToken?.data?.data?.accessToken
      localStorage.setItem('accessCode', JSON.stringify(tokens))
      if (body?.serviceName !== undefined) {
        await createNewService(body)
      } else if (sliderLevel !== undefined) {
        await setLogLevel(body, sliderLevel)
      } else {
        await handleDeleteService()
      }
    } catch (err) {
      history.push('/logout')
    }
  }
  const createNewService = async (body, authKey) => {
    try {
      await Axios.put(`${LEVEL_UPDATE}/configs/${id}`, body, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem('accessCode'))?.accessToken
          }`
        }
      })
      handelUpdateService(prev => !prev)
      setStatus('success')
    } catch (err) {
      if (err?.response?.status === 403) {
        err?.response?.data?.errors?.some(item => setStatus(item?.reason))
      } else if (err?.response?.status === 500) {
        setStatus('Internal Server Error')
      } else if (err?.response?.status === 409) {
        err?.response?.data?.errors?.some(item => setStatus(item?.reason))
      } else if (err?.response?.status === 401) {
        await refreshApi(body)
      } else {
        setStatus('Could not Update service')
      }
    }
    setTimeout(() => setStatus(''), 1500)
  }
  const handleCloseDeleteModal = () => {
    setDeleteModal(false)
  }

  const handleDeleteService = async () => {
    const request = { versionKey: versionKey }
    setStatus('loading')
    try {
      await Axios.delete(`${LEVEL_UPDATE}/configs/${id}`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem('accessCode'))?.accessToken
          }`
        },
        data: request
      })
      handelUpdateService(prev => !prev)
      setStatus('success')
      setDeleteModal(false)
    } catch (err) {
      if (err?.response?.status === 403) {
        setStatus('Unauthorised')
      } else if (err?.response?.status === 500) {
        setStatus('Internal Server Error')
      } else if (err?.response?.status === 401) {
        await refreshApi(request)
      } else {
        setStatus('Could not Update service')
      }
    }
    setTimeout(() => setStatus(''), 1500)
  }
  return (
    <TableRow hover tabIndex={-1} key={id} data-testid="tableRow--test">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
        data-testid="servicemodal--test"
      >
        <Fade in={open}>
          <div
            className={classes.paper}
            data-testid="servicemodal__dialog--test"
          >
            <h2 className={classes.modal__title} id="transition-modal-title">
              <span>Update service</span>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => handleClose()}
                data-testid="modal__close--test"
              >
                <CloseRoundedIcon
                  color="secondary"
                  fontSize="large"
                  data-testid="updateCloseIcon--test"
                />
              </IconButton>
            </h2>
            <section id="transition-modal-description">
              <form
                autoComplete="off"
                className={classes.modal__body}
                onSubmit={event => handleSubmit(event)}
                data-testid="servicemodal__form--test"
              >
                <div className={classes.modal__subheading}>
                  Service Details :
                </div>
                <section className={classes.modal__halfinput}>
                  <TextField
                    variant="outlined"
                    className={classes.modal__input}
                    id="standard-required-name"
                    type="text"
                    label="Service Name"
                    color="secondary"
                    value={updatedServiceName}
                    onChange={e => setUpdatedServiceName(e.target.value)}
                    data-testid="servicenametextfield-test"
                  />
                </section>
                <section className={classes.modal__halfinput}>
                  <TextField
                    variant="outlined"
                    className={`${classes.modal__input} ${classes['modal__input--half']}`}
                    id="standard-required-owner"
                    type="text"
                    label="Namespace"
                    color="secondary"
                    value={updatedNamespace}
                    data-testid="namespacetextfield-test"
                    onChange={e => setUpdatedNamespace(e.target.value)}
                  />
                  <TextField
                    variant="outlined"
                    className={`${classes.modal__input} ${classes['modal__input--half']}`}
                    id="standard-required-cluster"
                    type="text"
                    label="Cluster"
                    color="secondary"
                    value={updatedCluster}
                    data-testid="clustertextfield-test"
                    onChange={e => setUpdatedCluster(e.target.value)}
                  />
                </section>
                {status === 'loading' ? (
                  <LoadingRoller />
                ) : status === 'success' ? (
                  <div
                    className={classes.modal__success}
                    data-testid={'successtext--test'}
                  >
                    <CheckCircleRoundedIcon
                      className={classes.modal__successicon}
                    />
                    Service Updated successfully
                  </div>
                ) : status === '' ? (
                  <Button color="secondary" variant="contained" type="submit">
                    Update
                  </Button>
                ) : (
                  <div className={classes.modal__error}>
                    <CancelIcon
                      color="secondary"
                      className={classes.modal__erroricon}
                    />
                    {status}
                  </div>
                )}
              </form>
            </section>
          </div>
        </Fade>
      </Modal>
      <EnhancedTableCell rowData={rowData} labelId={labelId} />
      <TableCell
        align="center"
        key={`slider-${id}`}
        className={classes.tablecell__slider}
      >
        <ServiceTableSlider
          key={`discrete-slider-${id}`}
          defaultLevel={level}
          serviceName={name}
          namespace={namespace}
          status={requestStatus}
          handleStatusChange={setRequestStatus}
          handleLogChange={handleChange}
        />
      </TableCell>
      <TableCell
        align="center"
        key={`edit-delete-${id}`}
        className={classes.tablecell__name}
      >
        <div className={classes.action_icons}>
          <Edit
            className={classes.deleteEditIcon}
            onClick={handleOpen}
            data-testid="editCell--test"
          />
          <Delete
            className={classes.deleteEditIcon}
            onClick={handleOpenDeleteModal}
            data-testid="deleteCell--test"
          />
        </div>
      </TableCell>
      {deleteModal && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={deleteModal}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 500 }}
          data-testid="servicemodal--test"
        >
          <Fade in={deleteModal}>
            <div
              className={classes.paper}
              data-testid="servicemodal__dialog--test"
            >
              <h3 className={classes.modal__title} id="transition-modal-title">
                <span>
                  Are you sure you want to delete {updatedServiceName} service ?
                </span>
              </h3>
              <div className={classes.deleteConfirmation}>
                {(status === 'Unauthorised' ||
                  status === 'Internal Server Error' ||
                  status === 'Could not Update service') && (
                  <div className={classes.modal__error}>
                    <CancelIcon
                      color="secondary"
                      className={classes.modal__erroricon}
                    />
                    {status}
                  </div>
                )}
                <Button
                  onClick={handleCloseDeleteModal}
                  color="secondary"
                  variant="contained"
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={status === 'loading'}
                  onClick={handleDeleteService}
                  sx={{ color: 'white' }}
                >
                  Yes
                </Button>
              </div>
            </div>
          </Fade>
        </Modal>
      )}
    </TableRow>
  )
}

export default ServiceTableRow
