import React, { useState } from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { Add, Delete, Edit, Remove } from '@material-ui/icons'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import { Button } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import CancelIcon from '@material-ui/icons/Cancel'
import CloseRoundedIcon from '@material-ui/icons/Close'
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded'
import IconButton from '@material-ui/core/IconButton'
import { LoadingRoller } from '../LoadingRoller'
import Axios from 'axios'
import CofigsTableChip from './CofigsTableChip'
import { useTableRowStyles } from '../components/TableStyles'
import EnhancedTableCell from '../components/TableCell'
import { useHistory } from 'react-router'
import { LEVEL_UPDATE } from '../constants'

export const EnhancedConfigsEditField = ({
  configsList,
  configNames,
  handleConfigsList
}) => {
  const classes = useTableRowStyles()
  const [value, setValue] = useState(configsList[configNames])
  const handleValueChange = (value, key) => {
    setValue(value)
    handleConfigsList(prevState => ({
      ...prevState,
      [key]: value
    }))
  }
  const handleRemove = key => {
    const configsAfterRemove = configsList
    delete configsAfterRemove[key]
    handleConfigsList({
      ...configsList,
      ...configsAfterRemove
    })
  }
  return (
    <section
      className={classes.modal__addConfigsinput}
      data-testid="editMultipleQuery--test"
    >
      <TextField
        variant="outlined"
        className={`${classes.modal__input} ${classes['modal__configs__input--half']}`}
        id="standard-required-owner"
        type="text"
        color="secondary"
        value={configNames}
        disabled={true}
      />
      <TextField
        variant="outlined"
        data-testid="valueTextField--test"
        className={`${classes.modal__input} ${classes['modal__configs__input--half']}`}
        id="standard-required-value"
        type="text"
        color="secondary"
        value={value}
        onChange={e => handleValueChange(e.target.value, configNames)}
      />
      <Remove
        className={classes.configsRemove}
        onClick={() => handleRemove(configNames)}
        data-testid="removeMultipleQuery--test"
      />
    </section>
  )
}
export const EnhancedConfigsEntryField = ({
  handleConfigsList,
  setRemainingKey,
  setRemainingValue
}) => {
  const classes = useTableRowStyles()
  const [configKey, setConfigKey] = useState('')
  const [configValue, setConfigValue] = useState('')

  const handleValueChange = value => {
    setConfigValue(value)
    setRemainingValue(value)
  }
  const handleKeyChange = value => {
    setConfigKey(value)
    setRemainingKey(value)
  }
  const handleAdd = (key, value) => {
    handleConfigsList(prevState => ({
      ...prevState,
      [key]: value
    }))
    setRemainingKey('')
    setRemainingValue('')
    setConfigKey('')
    setConfigValue('')
  }
  return (
    <section className={classes.modal__addConfigsinput}>
      <TextField
        variant="outlined"
        className={`${classes.modal__input} ${classes['modal__configs__input--half']}`}
        id="standard-required-key"
        type="text"
        label="key"
        color="secondary"
        value={configKey}
        onChange={e => handleKeyChange(e.target.value)}
      />

      <TextField
        variant="outlined"
        className={`${classes.modal__input} ${classes['modal__configs__input--half']}`}
        id="standard-required-value"
        type="text"
        label="value"
        color="secondary"
        value={configValue}
        onChange={e => handleValueChange(e.target.value)}
      />
      {configKey !== '' ? (
        <Add
          className={classes.configsAdd}
          onClick={() => handleAdd(configKey, configValue)}
          data-testid="addMultipleQuery--test"
        />
      ) : (
        <div className={classes.emptyContainer}></div>
      )}
    </section>
  )
}
const ConfigsTableRow = ({ rowData, labelId, handleConfigsAdded }) => {
  const classes = useTableRowStyles()
  const history = useHistory()
  const { cluster, name, id, namespace, versionKey, config } = rowData
  const [open, setOpen] = useState(false)
  const [remainingKey, setRemainingKey] = useState('')
  const [remainingValue, setRemainingValue] = useState('')
  const [deleteModal, setDeleteModal] = useState(false)
  const [editableConfigsList, setEditableConfigsList] = useState(
    JSON.parse(JSON.stringify(config))
  )
  const [response, setResponse] = useState(
    JSON.parse(localStorage.getItem('accessCode'))
  )

  const [addConfigsList, setAddConfigsList] = useState({})
  const handleClose = () => {
    setOpen(false)
  }
  const [status, setStatus] = useState('')
  const handleOpen = () => {
    setStatus('')
    setOpen(true)
  }
  const handleSubmit = async e => {
    e.preventDefault()

    if (remainingKey !== '') {
      addConfigsList[remainingKey] = remainingValue
    }
    const finalList = {
      ...editableConfigsList,
      ...addConfigsList
    }
    const request = {
      serviceName: name,
      cluster,
      config: finalList,
      versionKey,
      namespace
    }
    setStatus('loading')
    await createNewService(request, response?.accessToken)
  }
  const refreshApi = async body => {
    try {
      const newAccessToken = await Axios.post(
        `${LEVEL_UPDATE}/refresh`,
        { refreshToken: response?.refreshToken },
        {
          headers: {
            Authorization: `Bearer ${response?.accessToken}`
          }
        }
      )
      setResponse(prev => ({
        ...prev,
        accessToken: newAccessToken?.data?.data?.accessToken
      }))
      const tokens = JSON.parse(localStorage.getItem('accessCode')) || {}
      tokens.accessToken = newAccessToken?.data?.data?.accessToken
      localStorage.setItem('accessCode', JSON.stringify(tokens))
      if (body?.serviceName !== undefined) {
        await createNewService(body)
      } else {
        await handleDeleteService()
      }
    } catch (err) {
      history.push('/logout')
    }
  }
  const createNewService = async (body, authKey) => {
    try {
      await Axios.put(`${LEVEL_UPDATE}/configs/${id}`, body, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem('accessCode'))?.accessToken
          }`
        }
      })
      setStatus('success')
      handleConfigsAdded(prev => !prev)
    } catch (error) {
      if (error.response.status === 403) {
        error?.response?.data?.errors?.some(item => setStatus(item?.reason))
      } else if (error.response.status === 500) {
        setStatus('Internal Server Error')
      } else if (error?.response?.status === 401) {
        await refreshApi(body)
      } else {
        setStatus('Could not Update Config')
      }
    }
    setTimeout(() => setStatus(''), 1500)
  }
  const handleOpenDeleteModal = () => {
    setStatus('')
    setDeleteModal(true)
  }
  const handleCloseDeleteModal = () => {
    setDeleteModal(false)
  }
  const handleDeleteService = async () => {
    const request = { versionKey: versionKey }
    setStatus('loading')
    try {
      await Axios.delete(`${LEVEL_UPDATE}/configs/${id}`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem('accessCode'))?.accessToken
          }`
        },
        data: request
      })
      handleConfigsAdded(prev => !prev)
      setStatus('success')
      setDeleteModal(false)
    } catch (err) {
      if (err?.response?.status === 403) {
        setStatus('Unauthorised')
      } else if (err?.response?.status === 500) {
        setStatus('Internal Server Error')
      } else if (err?.response?.status === 401) {
        await refreshApi(request)
      } else {
        setStatus('Could not Update service')
      }
      setTimeout(() => setStatus(''), 1500)
    }
  }
  return (
    <TableRow hover tabIndex={-1} key={id} data-testid="tableRow--test">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
        data-testid="configmodal--test"
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 className={classes.modal__title} id="transition-modal-title">
              <span>Update Configs</span>

              <IconButton
                edge="start"
                color="inherit"
                onClick={() => handleClose()}
                data-testid="modal__close--test"
              >
                <CloseRoundedIcon color="secondary" fontSize="large" />
              </IconButton>
            </h2>
            <section id="transition-modal-description">
              <form
                autoComplete="off"
                className={classes.modal__body}
                onSubmit={e => handleSubmit(e)}
                data-testid="configmodal__form--test"
              >
                <div className={classes.modal__subheading}>Configs :</div>

                <EnhancedConfigsEntryField
                  handleConfigsList={setAddConfigsList}
                  configsList={addConfigsList}
                  remainingKey={remainingKey}
                  remainingValue={remainingValue}
                  setRemainingKey={setRemainingKey}
                  setRemainingValue={setRemainingValue}
                />

                {Object.keys(addConfigsList)
                  .filter(config => config !== remainingKey)
                  .map((configNames, index) => (
                    <EnhancedConfigsEditField
                      key={index}
                      configsList={addConfigsList}
                      configNames={configNames}
                      handleConfigsList={setAddConfigsList}
                    />
                  ))}
                <div className={classes.modal__subheading}>
                  Existing Configs:
                </div>

                {Object.keys(editableConfigsList).length > 0 ? (
                  Object.keys(editableConfigsList).map((configNames, index) => (
                    <EnhancedConfigsEditField
                      key={'editConfigs' + index}
                      configsList={editableConfigsList}
                      configNames={configNames}
                      handleConfigsList={setEditableConfigsList}
                    />
                  ))
                ) : (
                  <div>
                    <span className={classes.emptyConfigsText}>
                      {' '}
                      No Configs Exist
                    </span>{' '}
                    <br /> Add New Configs
                  </div>
                )}
                {status === 'loading' ? (
                  <LoadingRoller />
                ) : status === 'success' ? (
                  <div className={classes.modal__success}>
                    <CheckCircleRoundedIcon
                      className={classes.modal__successicon}
                    />
                    Configs Added Successfully
                  </div>
                ) : status === '' ? (
                  <Button color="secondary" variant="contained" type="submit">
                    Update Configs
                  </Button>
                ) : (
                  <div className={classes.modal__error}>
                    <CancelIcon
                      color="secondary"
                      className={classes.modal__erroricon}
                    />
                    {status}
                  </div>
                )}
              </form>
            </section>
          </div>
        </Fade>
      </Modal>

      <EnhancedTableCell rowData={rowData} labelId={labelId} />

      <TableCell
        align="center"
        key={`configs-${id}`}
        className={classes.tablecell__configs}
      >
        <CofigsTableChip
          configKey={Object.keys(config)[0]}
          configValue={config[Object.keys(config)[0]]}
          configs={config}
          name={name}
        />
      </TableCell>
      <TableCell
        align="center"
        key={`edit-${id}`}
        className={classes.tablecell__configs}
        component="th"
        id={labelId}
        scope="row"
        padding="none"
        data-testid="tableCell__name--test"
      >
        <div className={classes.action_icons}>
          <Edit
            data-testid="editConfigsButton--test"
            className={classes.editConfigs}
            onClick={() => handleOpen()}
          />
          <Delete
            className={classes.editConfigs}
            onClick={handleOpenDeleteModal}
            data-testid="deleteCell--test"
          />
        </div>
      </TableCell>
      {deleteModal && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={deleteModal}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 500 }}
          data-testid="servicemodal--test"
        >
          <Fade in={deleteModal}>
            <div
              className={classes.paper}
              data-testid="servicemodal__dialog--test"
            >
              <h3 className={classes.modal__title} id="transition-modal-title">
                <span>Are you sure you want to delete {name} service ?</span>
              </h3>
              <div className={classes.deleteConfirmation}>
                {(status === 'Unauthorised' ||
                  status === 'Internal Server Error' ||
                  status === 'Could not Update service') && (
                  <div className={classes.modal__error}>
                    <CancelIcon
                      color="secondary"
                      className={classes.modal__erroricon}
                    />
                    {status}
                  </div>
                )}
                <Button
                  onClick={handleCloseDeleteModal}
                  color="secondary"
                  variant="contained"
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={status === 'loading'}
                  onClick={handleDeleteService}
                  sx={{ color: 'white' }}
                >
                  Yes
                </Button>
              </div>
            </div>
          </Fade>
        </Modal>
      )}
    </TableRow>
  )
}

export default ConfigsTableRow
